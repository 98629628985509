<script setup>
</script>

<template>
<div class="google-btn">
  <div class="google-icon-wrapper">
    <img class="google-icon-svg" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
  </div>
  <p class="btn-text"><b>Sign in with Google</b></p>
</div>
</template>
