<script setup>
import Avatar from './Avatar.vue'
import GoogleAuthButton from './buttons/GoogleAuthButton.vue';
import { useAuth } from '@/firebase'
const { user, isLogin, signOut, signIn } = useAuth()
</script>

<template>
  <nav>
    <div>
      <h1><strong>elemental</strong> chat</h1>

      <div v-if="isLogin" class="login">
        <Avatar :src="user.photoURL" />
        <button class="text-gray-400 hover:text-white" @click="signOut">
          Sign Out
        </button>
      </div>
      <GoogleAuthButton v-else @click="signIn" />
    </div>


  </nav>
</template>