<template>
    <svg 
    class="icon-send hover:text-orange-500"
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    >
        <path 
        fill="currentColor"
        d="M505.63,210.53L284.923,84.807c-8.752-5.258-19.894,1.047-19.894,11.257v52.654c-3.463,0.511-6.924,1.028-10.386,1.545c-28.818,4.083-57.729,11.476-85.041,21.488c-48.31,17.709-93.336,46.682-124.516,88.151C12.64,303.055-2.837,358.279,0.428,412.027c0.003,0.055,0.007,0.11,0.01,0.167c0.618,9.756,8.924,17.236,18.692,16.882c0.206-0.007,0.41-0.015,0.614-0.023c8.028-0.29,14.941-5.83,16.884-13.626c0.035-0.143,0.069-0.274,0.099-0.391c5.501-21.106,14.507-41.418,26.846-59.42c21.002-30.641,51.981-51.928,87.972-60.952c36.335-9.111,73.532-6.725,110.068-0.4c2.229,0.386,3.417,0.591,3.417,0.591v52.654c0,10.21,11.142,16.515,19.894,11.256l220.708-125.723C514.123,227.942,514.123,215.632,505.63,210.53z" />
    </svg>
</template>